import React from 'react'
import {Link} from 'gatsby'
import WrapperStyle from './ContentBanner.style';
const ContentBanner = ({homePageUrl, homePageText, activePageText, subDirectories =[]}) => {
    return (
      <WrapperStyle>
        <div className="page-title-area">
            <div className="container">
                <div className="page-title-content">
                    <ul>
                        <li>
                            <Link to={homePageUrl}>
                                {homePageText}
                            </Link>
                        </li>
                      {subDirectories.length > 0 ? subDirectories.map((x, i) => {
                          return (<li key={i}>
                            <Link to={x.url} >{x.name}</Link>
                          </li>)
                        })
                        : ''}
                        <li>{activePageText}</li>
                    </ul>
                </div>
            </div>
        </div>
      </WrapperStyle>
    )
};

export default ContentBanner;