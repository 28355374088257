import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/preAddPreSave/manga/2p.jpg';
import {StaticImage} from 'gatsby-plugin-image';
import starIcon from '../../../assets/images/star-icon.png';
import howitsWork from '../../../assets/images/about/about-img10.png';
import {Link} from 'gatsby';
import ScriptTag from 'react-script-tag';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="漫画でわかるプリアド・プリセーブマーケティング"
		  desc="漫画でAppleMusicのプリアド(Pre-Add)、Spotifyのプリセーブ(Pre-Save)の効果とマーケティング方法について解説しています。"
		  banner={exampleMetaImage}
		  pathname={'/examples/pre-add-pre-save/manga'}
		  type={'Article'}
		  shortName="漫画でわかるプリアド・プリセーブマーケティング"
		  createdAt="2021-03-02"
		/>
	  <Navbar />
	  <ContentBanner
		  pageTitle="漫画でわかるプリアド・プリセーブ"
		homePageText="Home"
		homePageUrl="/"
		activePageText="漫画でわかるプリアド・プリセーブ"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/pre-add-pre-save',
		  name: 'プリアド・プリセーブ'
		}]}
	  />
	  <section className="pt-100">
		<div className="container">
		  <div className="row">
			<div className="col-lg-2 col-sm-12"></div>
			<div className="col-lg-8 col-sm-12">
				<StaticImage src="../../../assets/images/examples/preAddPreSave/manga/1p.jpg" />
				<StaticImage src="../../../assets/images/examples/preAddPreSave/manga/2p.jpg" />
				<StaticImage src="../../../assets/images/examples/preAddPreSave/manga/3p.jpg" />
				<StaticImage src="../../../assets/images/examples/preAddPreSave/manga/4.1p.jpg" />
			</div>
			<div className="col-lg-2 col-sm-12"></div>
		  </div>
		</div>
	  </section>
	  <section className="how-its-work-area ptb-100 ">
		<div className="container">
		  <div className="section-title">
			<h2><u>バズが生まれる３つの理由</u></h2>
			<p></p>
		  </div>
		  <div className="row align-items-center">
			<div className="col-lg-7 col-md-12">
			  <div className="how-its-work-content">
                            {/*<span className="sub-title">*/}
                            {/*    <img src={starIcon} alt="choose" />*/}
                            {/*    Why Choose Us*/}
                            {/*</span>*/}


				<div className="inner-box">
				  <div className="single-item">
					<div className="count-box">
					  1
					</div>
					<h3>AIに好影響をあたえる</h3>
					<p>プリアド・プリセーブで新曲が保存いいねされ、リリース日に再生数が大きく跳ね上がりAIにいい影響を与えるきっかけを作る</p>
				  </div>
				  <div className="single-item">
					<div className="count-box">
					  2
					</div>
					<h3>人気プレイリストやランキングに入る</h3>
					<p>AIアルゴリズムがリスナーのアクションに反応して、人気プレイやランキングに入る可能性が高くなる</p>
				  </div>
				  <div className="single-item">
					<div className="count-box">
					  3
					</div>
					<h3>バズがうまれる</h3>
					<p>人気プレイリストやランキングに入ると世界中に音楽ファンがいるキュレーターやサブミッションメディアが取り上げ話題となる</p>
				  </div>
				</div>
			  </div>
			</div>

			<div className="col-lg-5 col-md-12">
			  <div className="how-its-work-image">
				<img src={howitsWork} alt="choose" />
			  </div>
			</div>
		  </div>
		</div>
	  </section>
	  <section>
		<div className="container">
		  <div className="row">
			<div className="col-lg-2 col-sm-12"></div>
			<div className="col-lg-8 col-sm-12">
			  <StaticImage src="../../../assets/images/examples/preAddPreSave/manga/4.2p.jpg" />
			  <StaticImage src="../../../assets/images/examples/preAddPreSave/manga/5p.jpg" />
			  <StaticImage src="../../../assets/images/examples/preAddPreSave/manga/6p.jpg" />
			  <StaticImage src="../../../assets/images/examples/preAddPreSave/manga/7p.jpg" />
			  <StaticImage src="../../../assets/images/examples/preAddPreSave/manga/8p.jpg" />
			  <StaticImage src="../../../assets/images/examples/preAddPreSave/manga/9p.jpg" />
			</div>
			<div className="col-lg-2 col-sm-12"></div>
		  </div>
		</div>
	  </section>
	  <section className="how-its-work-area ptb-100">
		<div className="container services-details-desc">
		  <div className="section-title">
			<h2><u>リタッチのここが凄い！</u></h2>
			<p></p>
		  </div>
		  <div className="row">
			<div className="col-lg-4 col-md-6 col-sm-6">
			  <div className="single-what-we-do-box">
				<div className="icon" style={{fontSize:'65px', backgroundColor: 'initial', color:'initial'}}>
				  <i className="fab fa-twitter" style={{color:'#1DA1F2'}}></i>
				</div>
				<h3>
					圧倒的な拡散力
				</h3>
				<p>リリースイベントを拡散して多くのプリアド・プリセーブを獲得できる仕組みを提供します。</p>
				<div className="content">
				  <ul>
					<li>Twitterのリツイート・いいね</li>
					<li>友だち紹介</li>
					<li>LINEのシェア</li>
				  </ul>
				</div>
			  </div>
			</div>

			<div className="col-lg-4 col-md-6 col-sm-6">
			  <div className="single-what-we-do-box">
				<div className="icon" style={{fontSize:'65px', backgroundColor: 'initial', color:'initial'}}>
				  <i className="fas fa-gifts" style={{color:'#FF0000'}}></i>
				</div>
				<h3>
					豊富な賞品提供
				</h3>
				<p>複数の賞品を提供したり、応募者全員に非公開音源をプレゼントなど、魅力的な賞品を設定できます。</p>

				<div className="content">
				  <ul>
					<li>複数賞品対応</li>
					<li>非公開音源</li>
					<li>プライベート写真</li>
					<li>その他</li>
				  </ul>
				</div>
			  </div>
			</div>

			<div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
			  <div className="single-what-we-do-box">
				<div className="icon" style={{fontSize:'65px', backgroundColor: 'initial', color:'initial'}}>
				  <i className="fas fa-users" style={{color:'#00c300'}}></i>
				</div>
				<h3>
					ユーザー情報の取得
				</h3>
				<p>リスナーのファン化を推進するためエンゲージメントを高める、コミュニケーション手段を取得できます。</p>
				<div className="content">
				  <ul>
					<li>公式LINE連携</li>
					<li>メールアドレス取得</li>
					<li>アンケート実施</li>
					<li>応募地域の取得</li>
				  </ul>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </section>
	  <section className="pb-100">
		<div className="container">
		  <div className="row">
			<div className="col-lg-2 col-sm-12"></div>
			<div className="col-lg-8 col-sm-12">
			  <StaticImage src="../../../assets/images/examples/preAddPreSave/manga/10p.jpg" />
			</div>
			<div className="col-lg-2 col-sm-12"></div>
		  </div>
		</div>
	  </section>
	  {/*<section className="solutions-area pb-70">*/}
		{/*<div className="container">*/}
		{/*  <div className="section-title">*/}
		{/*	<h2><u>サポートキャンペーン実施中！</u>*/}
		{/*	</h2>*/}
		{/*	<p>*/}
		{/*	  リタッチでは現在アーティストサポートを強化しております。プリアド・プリセーブを無料で試せるチャンスです。*/}
		{/*	</p>*/}
		{/*  </div>*/}
		{/*  <div className="row">*/}
		{/*	<div className="col-lg-12 col-sm-12">*/}
		{/*	  <a data-retach-widget="embed" href="https://retach.biz/promotions/06de013d222f4e6116" rel="nofollow">リタッチ</a>*/}
		{/*	  <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>*/}
		{/*	</div>*/}
		{/*  </div>*/}
		{/*</div>*/}
	  {/*</section>*/}
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples