import styled from 'styled-components';
const DocumentStyle = styled.div` 

     .page-title-area {
      padding-top: 100px;
      padding-bottom: 10px;
     }
     .page-title-content ul li:last-child {
      color: #acb2c3;
      font-weight: initial;
     }
   
`;

export default DocumentStyle